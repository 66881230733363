import { useModals } from '@/compositions/modals'

type UseInvoices = () => {
  openAddingEmailModal: () => void
  openForwardModal: () => void
}

const useInvoices: UseInvoices = () => {
  const { openModal } = useModals()

  const openAddingEmailModal = () => {
    openModal('addAccountingEmail')
  }

  const openForwardModal = () => {
    openModal('singleInput', {
      label: 'Email address',
      placeholder: 'Enter email address',
      modalTitle: 'Forward invoice via email',
      description: 'Please enter you email address below. All selected invoices will be forwarded to that address.',
      btnText: 'Send invoices',
    })
  }

  return {
    openAddingEmailModal,
    openForwardModal,
  }
}

export default useInvoices
