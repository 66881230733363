
import { computed, defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import TmButton from '@/components/shared/TmButton.vue'
import AdminInvoicesTable from '@/components/pages/admin/billing/invoices/AdminInvoicesTable.vue'
import AdminInvoicesFilter from '@/components/pages/admin/billing/invoices/AdminInvoicesFilter.vue'
import AdminInvoicesEmpty from '@/components/pages/admin/billing/invoices/AdminInvoicesEmpty.vue'
import { useModes } from '@/compositions/modes'
import useInvoices from '@/compositions/account/useInvoices'
import { getTableData } from '@/services/tableService'
import { formatDate } from '@/services/dateTimeService'
import type { TableHeaders } from '@/definitions/shared/types'
import type { InvoiceType } from '@/definitions/account/invoices/types'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    AdminInvoicesEmpty,
    AdminInvoicesFilter,
    AdminInvoicesTable,
    TmButton,
    TmAlert,
    PageContent,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const { openAddingEmailModal } = useInvoices()
    const { isSmMax } = useBreakpoints()

    const search = ref('')
    const selected = ref<InvoiceType[]>([])
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Invoice', value: 'invoice-slot' },
      { text: 'Amount', value: 'amount', format: (val: string) => `$${val}` },
      { text: 'Payment method', value: 'paymentMethod' },
      { text: 'Date', value: 'date', format: (val: string) => formatDate(val), width: '20%' },
      { text: 'Status', value: 'status-slot' },
    ])
    const tableItems = ref<InvoiceType[]>(getTableData('invoices'))
    const filteredTableItems = computed<InvoiceType[]>(() => {
      return tableItems.value.filter((item) => item.invoice.toString().includes(search.value.toLowerCase()))
    })

    const showAlert = ref(true)

    return {
      search,
      selected,
      tableHeaders,
      tableItems,
      isEmptyMode,
      openAddingEmailModal,
      filteredTableItems,
      showAlert,
      isSmMax,
    }
  },
})
