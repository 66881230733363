import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_admin_invoices_dropdown = _resolveComponent("admin-invoices-dropdown")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, {
    headers: _ctx.headers,
    items: _ctx.items,
    "items-name": _ctx.itemsName,
    "show-selection": _ctx.showSelection
  }, {
    "body-cell-invoice-slot": _withCtx((props) => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_tm_icon, {
          name: "print",
          size: "xLarge",
          class: "mr-2 distinct--text"
        }),
        _createElementVNode("span", null, _toDisplayString(props.row.invoice), 1)
      ])
    ]),
    "body-cell-status-slot": _withCtx((props) => [
      _createVNode(_component_tm_status, {
        name: props.row.status.name,
        outline: props.row.status.outline,
        color: props.row.status.color
      }, null, 8, ["name", "outline", "color"])
    ]),
    "body-cell-actions": _withCtx(() => [
      _createVNode(_component_admin_invoices_dropdown)
    ]),
    _: 1
  }, 8, ["headers", "items", "items-name", "show-selection"]))
}