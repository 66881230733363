
import { defineComponent } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import TmDropdownDownload from '@/components/shared/dropdowns/TmDropdownDownload.vue'
import useInvoices from '@/compositions/account/useInvoices'

export default defineComponent({
  components: {
    TmDropdownDownload,
    TopFilterButton,
    TopFilter,
  },
  props: {
    selected: {
      type: Array,
    },
  },
  setup() {
    const { openForwardModal } = useInvoices()

    return {
      openForwardModal,
    }
  },
})
